<template>
	<v-data-table
		v-bind="$attrs"
		class="elevation-1 my-1"
		:headers="internHeaders"
		:items="value"
		:loading="loading"
		:pagination="pagination"
		:total-items="pagination?.totalItems"
		@update:pagination="onPaginationUpdated($event)"
	>
		<template v-slot:headers="props">
			<tr>
				<th
					v-for="header in props.headers.filter(header => header.text)"
					:key="header.text"
					class="column"
					:class="{
						active: header.sortable && header.value == pagination.sortBy,
						asc: header.sortable && !pagination?.descending,
						desc: header.sortable && pagination?.descending,
						sortable: header.sortable,
						'text-xs-center': header.align == 'center',
						'text-xs-left': header.align != 'center'
					}"
					:style="header.width ? 'width:' + header.width : ''"
					@click="changeSort(header.value)"
				>
					<v-icon v-if="header.sortable" small>arrow_upward</v-icon>
					{{ header.text }}
				</th>
				<th :style="'width:24px;' + (sticky ? 'position:sticky;right: 0;' : '')">
					<w-checkbox :disabled="readonly" :indeterminate="checkboxState == 'indeterminate'" :value="checkboxState == 'selected'" @input="selectRows" />
				</th>
			</tr>
		</template>
		<template v-slot:items="{ item }">
			<slot name="items" :item="item" />
			<td :style="sticky ? 'position:sticky;right:0;' : ''">
				<w-checkbox
					:disabled="readonly"
					:false-value="falseValue"
					:indeterminate="item[selectedKey] != falseValue && item[selectedKey] != trueValue"
					:true-value="trueValue"
					:value="item[selectedKey]"
					@input="onInput(item, $event)"
				/>
			</td>
		</template>
		<template v-slot:actions-append>
			<slot name="actions-append"></slot>
		</template>
		<template v-slot:actions-prepend>
			<slot name="actions-prepend"></slot>
		</template>
		<template v-slot:footer>
			<slot name="footer"></slot>
		</template>
		<template v-slot:no-data>
			<slot name="no-data"></slot>
		</template>
		<template v-slot:no-result>
			<slot name="no-results"></slot>
		</template>
		<template v-slot:page-text="props">
			<slot name="page-text" :props="props"></slot>
		</template>
	</v-data-table>
</template>

<script>
export default {
	name: 'WSelectableDataTable',
	props: {
		headers: {
			required: true,
			type: Array
		},
		indeterminateCheckbox: {
			default: false,
			required: false,
			type: Boolean
		},
		loading: {
			default: false,
			required: false,
			type: Boolean
		},
		pagination: {
			default: () => ({}),
			required: false,
			type: Object
		},
		readonly: {
			default: false,
			required: false,
			type: Boolean
		},
		selectedKey: {
			default: 'selected',
			required: false,
			type: String
		},
		sticky: {
			default: true,
			required: false,
			type: Boolean
		},
		value: {
			default: () => [],
			required: false,
			type: Array
		}
	},
	computed: {
		checkboxState: function () {
			let result = 'indeterminate'
			if (this.value.length == 0 || this.value.every(row => row[this.selectedKey] == this.falseValue)) {
				result = 'unselected'
			} else if (this.value.every(row => row[this.selectedKey] == this.trueValue)) {
				result = 'selected'
			}

			return result
		},
		falseValue: function () {
			return this.indeterminateCheckbox ? 'none' : false
		},
		internHeaders: function () {
			const result = [...this.headers]
			result.push({ text: '' })
			return result
		},
		trueValue: function () {
			return this.indeterminateCheckbox ? 'all' : true
		}
	},
	methods: {
		changeSort: function (column) {
			if (!this.pagination) {
				this.updatePagination({
					descending: false,
					sortBy: column
				})
			} else if (this.pagination.sortBy == column) {
				this.updatePagination(
					Object.assign(
						{ ...this.pagination },
						{
							descending: !this.pagination.descending
						}
					)
				)
			} else {
				this.updatePagination(
					Object.assign(
						{ ...this.pagination },
						{
							descending: false,
							sortBy: column
						}
					)
				)
			}
		},
		onInput: function (row, event) {
			if (event == this.trueValue) {
				this.$emit('select', row)
			} else {
				this.$emit('unselect', row)
			}
		},
		onPaginationUpdated: function (paginationData) {
			this.updatePagination(Object.assign({ ...this.pagination }, paginationData))
		},
		selectRows: function (checkboxState) {
			if (checkboxState) {
				this.$emit('select-rows')
			} else {
				this.$emit('unselect-rows')
			}
		},
		updatePagination: function (paginationData) {
			this.$emit('update:pagination', paginationData)
		}
	}
}
</script>

<style scoped>
:deep(.theme--light td),
:deep(.theme--light th) {
	background-color: rgb(255, 255, 255);
}

:deep(.theme--dark td),
:deep(.theme--dark th) {
	background-color: #424242;
}
</style>