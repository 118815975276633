var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',_vm._b({staticClass:"elevation-1 my-1",attrs:{"headers":_vm.internHeaders,"items":_vm.value,"loading":_vm.loading,"pagination":_vm.pagination,"total-items":_vm.pagination?.totalItems},on:{"update:pagination":function($event){return _vm.onPaginationUpdated($event)}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',[_vm._l((props.headers.filter(header => header.text)),function(header){return _c('th',{key:header.text,staticClass:"column",class:{
					active: header.sortable && header.value == _vm.pagination.sortBy,
					asc: header.sortable && !_vm.pagination?.descending,
					desc: header.sortable && _vm.pagination?.descending,
					sortable: header.sortable,
					'text-xs-center': header.align == 'center',
					'text-xs-left': header.align != 'center'
				},style:(header.width ? 'width:' + header.width : ''),on:{"click":function($event){return _vm.changeSort(header.value)}}},[(header.sortable)?_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]):_vm._e(),_vm._v(" "+_vm._s(header.text)+" ")],1)}),_c('th',{style:('width:24px;' + (_vm.sticky ? 'position:sticky;right: 0;' : ''))},[_c('w-checkbox',{attrs:{"disabled":_vm.readonly,"indeterminate":_vm.checkboxState == 'indeterminate',"value":_vm.checkboxState == 'selected'},on:{"input":_vm.selectRows}})],1)],2)]}},{key:"items",fn:function({ item }){return [_vm._t("items",null,{"item":item}),_c('td',{style:(_vm.sticky ? 'position:sticky;right:0;' : '')},[_c('w-checkbox',{attrs:{"disabled":_vm.readonly,"false-value":_vm.falseValue,"indeterminate":item[_vm.selectedKey] != _vm.falseValue && item[_vm.selectedKey] != _vm.trueValue,"true-value":_vm.trueValue,"value":item[_vm.selectedKey]},on:{"input":function($event){return _vm.onInput(item, $event)}}})],1)]}},{key:"actions-append",fn:function(){return [_vm._t("actions-append")]},proxy:true},{key:"actions-prepend",fn:function(){return [_vm._t("actions-prepend")]},proxy:true},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true},{key:"no-data",fn:function(){return [_vm._t("no-data")]},proxy:true},{key:"no-result",fn:function(){return [_vm._t("no-results")]},proxy:true},{key:"page-text",fn:function(props){return [_vm._t("page-text",null,{"props":props})]}}],null,true)},'v-data-table',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }